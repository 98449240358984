@import '~@styles/variables.scss';

.form-input {
  padding: $form-field-wrapper-padding;

  &__label {
    padding: 0 4px;
    font-weight: 500;
    line-height: 24px;
  }

  &__input {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $color-gray-fields;
    border-radius: 10px;
    padding: 10px;
    background-color: $color-white;
    font-size: $font-size-m;

    &--error {
      border-color: #c22;
      color: #c22;
    }
  }

  &__error {
    color: #c22;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
}
