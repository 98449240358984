@import '~@styles/variables.scss';

.material-tabs {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__header {
    display: flex;
    width: 100%;
  }

  &__tab-title {
    margin: 0 5px;
    margin-top: 30px;
    border: none;
    border-radius: 0;
    padding: 0 15px;
    padding-top: 5px;
    padding-bottom: 3px;
    background: none;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 2;
    text-transform: uppercase;
    cursor: pointer;

    &--active {
      border-bottom: 3px solid var(--color-main-dark);
      padding-bottom: 0;
      font-weight: 700;
    }

    &:focus {
      outline: none;
    }
  }

  &__content {
    width: 100%;
    margin-top: 20px;
  }

  @media screen and (max-width: $tablet-width) {
    &__tab-title {
      margin-top: 15px;
    }
  }
}
