@import '~@styles/variables.scss';

.signature-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  padding: 0;

  &__header {
    margin: 5px 0;
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
  }

  &__input-wrapper {
    width: 100%;
  }

  &__input {
    width: 100%;
  }

  &__submit-button-wrapper {
    display: block;
    width: 100%;
    margin-top: 40px;
    padding-right: 5px;
    text-align: right;
  }
}
